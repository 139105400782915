import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { ToastContainer } from "react-toastify";
import "./i18n";
import { useTranslation } from "react-i18next";

const useSetLanguage = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("EN");
  }, [i18n]);
};

export const AppWrapper = () => {
  useSetLanguage();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <RouterProvider router={router} />
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <AppWrapper />
  // </React.StrictMode>
);

reportWebVitals();
