import { createBrowserRouter, Navigate } from "react-router-dom";
import { PATH } from "./utils/pagePath";
import ProtectedRoute from "./auth/ProtectedRoute";
import PublicRoute from "./auth/PublicRoute";
import { lazy } from "react";

//Import Lazy Loading Pages Here
const LazyPostLogin = lazy(() => import("./layouts/MainLayout/Layout"));
const LazyLogin = lazy(() => import("./pages/Login/Login"));
const LazyAuthPage = lazy(() => import("./pages/Login/Index"));
const LazyRegistration = lazy(() => import("./pages/Login/Registration"));
const LazyDashboard = lazy(() => import("./pages/Dashboard/Dashboard"));

// Backend
const LazyBackend = lazy(() => import("./pages/Backend/AllDetails/Details"));

// PaymentInfo
const LazyScheduler = lazy(
  () => import("./pages/PaymentInfo/Scheduler/Scheduler")
);

const LazyTransaction = lazy(
  () => import("./pages/PaymentInfo/Transaction/Transaction")
);

const LazyPaymentDetails = lazy(
  () => import("./pages/PaymentInfo/PaymentDetails/PaymentDetails")
);

//ClientInfo
const LazyClientDetails = lazy(
  () => import("./pages/ClientInfo/ClientDetails/ClientDetails")
);
const LazyCostSheet = lazy(
  () => import("./pages/ClientInfo/CostSheet/CostSheet")
);
const LazyCancelBooking = lazy(
  () => import("./pages/ClientInfo/CancelBooking/CancelBooking")
);

//Inventory
const LazyInventorySetup = lazy(
  () => import("./pages/InventoryDetails/InventorySetup/Inventory")
);

const LazyTowerDetails = lazy(
  () => import("./pages/InventoryDetails/Towers/Towers")
);

const LazyUnitDetails = lazy(
  () => import("./pages/InventoryDetails/Units/Units")
);

const LazyFloorDetails = lazy(
  () => import("./pages/InventoryDetails/Floor/Floor")
);

const LazyFacilitiesDetails = lazy(
  () => import("./pages/InventoryDetails/FacilitiesDetails/Facilities")
);
const LazyTax = lazy(() => import("./pages/Master/Taxs/Taxs"));

// Inventory Master
const LazyFloorTheme = lazy(
  () => import("./pages/Master/FloorTheme/FloorTheme")
);
const LazyUnitType = lazy(() => import("./pages/Master/UnitType/UnitType"));
const LazyOwnedBy = lazy(() => import("./pages/Master/OwnedBy/OwnedBy"));

// PaymentType
const LazyPaymentType = lazy(
  () => import("./pages/Master/PaymentType/PaymentType")
);

// User Master
const LazyUserDetails = lazy(
  () => import("./pages/UsersMasters/UsersDetails/UsersDetails")
);

export const router = createBrowserRouter([
  {
    path: PATH.DEFAULT,
    element: <Navigate to={PATH.LOGIN} replace />,
  },
  {
    element: <p> Page Not Found </p>,
    path: PATH.PAGE_NOT_FOUND,
  },
  {
    element: (
      <PublicRoute>
        <LazyAuthPage />
      </PublicRoute>
    ),
    children: [
      {
        path: PATH.LOGIN,
        element: <LazyLogin />,
      },
      {
        path: PATH.REGISTRATION,
        element: <LazyRegistration />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <LazyPostLogin />
      </ProtectedRoute>
    ),
    children: [
      {
        path: PATH.DASHBOARD,
        element: <LazyDashboard />,
      },
      {
        path: PATH.BACKEND,
        element: <LazyBackend />,
      },

      // {
      //   path: "backend1",
      //   element: <LazyBackend1 />,
      // },
      // Payment Info
      {
        path: PATH.TRANSACTION,
        element: <LazyTransaction />,
      },
      {
        path: PATH.PENDING,
        element: <LazyPaymentDetails />,
      },
      {
        path: PATH.SCHEDULER,
        element: <LazyScheduler />,
      },
      // Client Info
      {
        path: PATH.COSTSHEET,
        element: <LazyCostSheet />,
      },
      {
        path: PATH.CLIENTDETAILS,
        element: <LazyClientDetails />,
      },

      {
        path: PATH.CANCELLEDBOOKING,
        element: <LazyCancelBooking />,
      },
      // Inventory Details
      {
        path: `${PATH.INVENTORYSETUP}`,
        element: <LazyInventorySetup />,
      },
      {
        path: `${PATH.TOWERDETAILS}`,
        element: <LazyTowerDetails />,
      },
      {
        path: `${PATH.UNIT}`,
        element: <LazyUnitDetails />,
      },
      {
        path: `${PATH.FLOOR}`,
        element: <LazyFloorDetails />,
      },
      {
        path: `${PATH.FACILITIES}`,
        element: <LazyFacilitiesDetails />,
      },
      {
        path: `${PATH.TAX}`,
        element: <LazyTax />,
      },

      // Inventory Master
      {
        path: `${PATH.FLOORTHEME}`,
        element: <LazyFloorTheme />,
      },
      {
        path: `${PATH.UNITTYPE}`,
        element: <LazyUnitType />,
      },
      {
        path: `${PATH.OWNEDBY}`,
        element: <LazyOwnedBy />,
      },

      //PaymentMaster
      {
        path: `${PATH.PAYMENTTYPE}`,
        element: <LazyPaymentType />,
      },

      //userMaster
      {
        path: `${PATH.USERDETAILS}`,
        element: <LazyUserDetails />,
      },
    ],
  },
]);
