
import { PATH } from "./pagePath";

export const COOKIES = {
  USER_INFO: "userInfo",
  REFERESH_TOKEN: "refreshToken",
  PROJECT_DETAILS: "projectID",
  CARPET_AREA: "CARPETAREA",
  PHASE_DETAILS: "phaseID",
};


export const ROLETYPECODE = {
  SYSTEM_ADMIN: "SA",
};

export const VALIDATION = {
  MAX_LENGTH: 20,
  MAX_COMPANY_NAME_LENGTH: 50,
  MIN_SKILLS: 2,
  MAX_SKILLS: 30,
  PASSWORD_MIN_LENGTH: 8,
  Max_EMAIL_LENGTH: 250,
  MAX_AGENCY_lENGTH: 100,
  MAX_QUALIFICATION_lENGTH: 100,
  MAX_POSITION_lENGTH: 50,
  MAX_NAME_LENGTH: 20,
};

// export const API_STATUS = {
//   SUCCESS: "Success",
//   FAILED: "Failed",
// };

export const LABELS = {
  ScheduleDailyLabel: [
    { name: "Once", key: "O" },
    { name: "Multiple", key: "M" },
  ],
  ScheduleMonthLabel: [
    { name: "Once", key: "O" },
    { name: "Twice", key: "T" },
  ],
  weekDataLabel: [
    { DAY_DESC: "S", DAY_CODE: 1 },
    { DAY_DESC: "M", DAY_CODE: 2 },
    { DAY_DESC: "T", DAY_CODE: 3 },
    { DAY_DESC: "W", DAY_CODE: 4 },
    { DAY_DESC: "T", DAY_CODE: 5 },
    { DAY_DESC: "F", DAY_CODE: 6 },
    { DAY_DESC: "S", DAY_CODE: 7 },
  ],
};

// export const OPTIONS = {
//   ScheduleDailyLabel: [
//     { name: "Once", key: "O" },
//     { name: "Multiple", key: "E" },
//   ],
//   ScheduleMonthLabel: [
//     { name: "Once", key: "O" },
//     { name: "Twice", key: "T" },
//   ],
//   scheduleList: [
//     { SCHEDULE_DESC: "Periodic Daily", PERIOD: "D", FREQUENCY_TYPE: "P", VALUE: "D" },
//     { SCHEDULE_DESC: "Periodic Weekly", PERIOD: "W", FREQUENCY_TYPE: "P", VALUE: "W" },
//     { SCHEDULE_DESC: "Periodic Monthly", PERIOD: "M", FREQUENCY_TYPE: "P", VALUE: "M" },
//     { SCHEDULE_DESC: "Run Hour Based", PERIOD: "", FREQUENCY_TYPE: "R", VALUE: "R" },
//     { SCHEDULE_DESC: "Run to Fail", PERIOD: "", FREQUENCY_TYPE: "F", VALUE: "F" },
//   ],
//   monthList: [
//     { MONTH_DESC: "Fixed Day", MONTH_OPTION: "1" },
//     { MONTH_DESC: "Fixed Week Day", MONTH_OPTION: "2" },
//   ],
//   weekNumList: [
//     { MONTHLY_2_WEEK_NUM: 1, VIEW: "1st" },
//     { MONTHLY_2_WEEK_NUM: 2, VIEW: "2nd" },
//     { MONTHLY_2_WEEK_NUM: 3, VIEW: "3rd" },
//     { MONTHLY_2_WEEK_NUM: 4, VIEW: "4th" },
//     { MONTHLY_2_WEEK_NUM: 5, VIEW: "5th" },
//   ],
// };



export const formatDate = (isoDateString: Date) => {
  const date = new Date(isoDateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
// "Management", "Sales", "Accounts", "Legal", "Design"

const role = ["Admin", "Management", "Sales", "Accounts", "Legal", "Design"]


export const options = {
  projectType: [
    { name: "Residential", value: 1 },
    { name: "Commercial", value: 2 },
  ],
  role,
  gender: ["Male", "Female"]
}

const allAccess = ["Admin", "Management"]

export const allMenuList = [
  {
    MODULE_CODE: "dashboard",
    URL: PATH.DASHBOARD,
    MODULE_DESCRIPTION: "Dashboard",
    ICON: "pi pi pi-home",
    ROLES: [...allAccess],
    ADD_RIGHTS: [...allAccess],
    EDIT_RIGHTS: [...allAccess],
    VIEW_RIGHTS: [...allAccess],
  },
  {
    MODULE_CODE: "backend",
    URL: PATH.BACKEND,
    MODULE_DESCRIPTION: "Backend",
    ICON: "pi pi-list",
    ROLES: [...allAccess],
    ADD_RIGHTS: [...allAccess],
    EDIT_RIGHTS: [...allAccess],
    VIEW_RIGHTS: [...allAccess],
  },
  {
    MODULE_CODE: "paymentInfo",
    MODULE_DESCRIPTION: "Payment Info",
    ICON: "pi pi-indian-rupee",
    ROLES: [role[0], role[1], role[2], role[3]],
    DETAIL: [
      {
        FUNCTION_DESC: "Proposed Payment",
        URL: PATH.SCHEDULER,
        ADD_RIGHTS: [...allAccess, "Sales"],
        EDIT_RIGHTS: [...allAccess, "Sales"],
        VIEW_RIGHTS: [...allAccess, "Sales"],
      },
      {
        FUNCTION_DESC: "Payment Recevied",
        URL: PATH.TRANSACTION,
        ADD_RIGHTS: [...allAccess],
        EDIT_RIGHTS: [...allAccess],
        VIEW_RIGHTS: [...allAccess, "Sales", "Accounts"],

      },
      {
        FUNCTION_DESC: "Payments Details",
        URL: PATH.PENDING,
        ADD_RIGHTS: [...allAccess, "Accounts"],
        EDIT_RIGHTS: [...allAccess],
        VIEW_RIGHTS: [...allAccess, "Sales", "Accounts"],

      },
    ],
  },
  {
    MODULE_CODE: "clientInfo",
    MODULE_DESCRIPTION: "ClientInfo",
    ICON: "pi pi-users",
    ROLES: [...allAccess, "Sales", "Legal"],
    DETAIL: [
      {
        FUNCTION_DESC: "Cost Sheet",
        URL: PATH.COSTSHEET,
        ADD_RIGHTS: [...allAccess, "Sales"],
        EDIT_RIGHTS: [...allAccess, "Sales"],

        VIEW_RIGHTS: [...allAccess, "Sales"],
      },
      {
        FUNCTION_DESC: "Booking Form",
        URL: PATH.CLIENTDETAILS,
        ADD_RIGHTS: [...allAccess, "Sales"],
        EDIT_RIGHTS: [...allAccess, "Sales"],
        VIEW_RIGHTS: [...allAccess, "Sales", "Legal"],
      },
      {
        FUNCTION_DESC: "Cancellation",
        URL: PATH.CANCELLEDBOOKING,
        VIEW_RIGHTS: [...allAccess, "Sales", "Legal"],
      },
    ],
  },
  {
    MODULE_CODE: "towerInfo",
    MODULE_DESCRIPTION: "Building Config",
    ICON: "pi pi-building",
    ROLES: [...allAccess, "Sales", "Design"],
    DETAIL: [
      {
        FUNCTION_DESC: "Unit Details",
        URL: "/unitdetails",
        ADD_RIGHTS: [...allAccess, "Design"],
        EDIT_RIGHTS: [...allAccess, "Design"],

        VIEW_RIGHTS: [...allAccess, "Sales", "Design"],
      },
      {
        FUNCTION_DESC: "Floor Details",
        URL: "/floordetails",
        ADD_RIGHTS: [...allAccess, "Design"],
        EDIT_RIGHTS: [...allAccess, "Design"],

        VIEW_RIGHTS: [...allAccess, "Sales", "Design"],
      },
    ],
  },
  {
    MODULE_CODE: "inventoryDetails",
    MODULE_DESCRIPTION: "Inventory Setup",
    ICON: "pi pi-building-columns",
    ROLES: [...allAccess, "Sales", "Design"],
    DETAIL: [
      {
        FUNCTION_DESC: "Inventory",
        URL: PATH?.INVENTORYSETUP,
        ADD_RIGHTS: [...allAccess, "Design"],
        EDIT_RIGHTS: [...allAccess, "Design"],

        VIEW_RIGHTS: [...allAccess, "Sales", "Design"],
      },
      {
        FUNCTION_DESC: "Facilities",
        URL: PATH?.FACILITIES,
        ADD_RIGHTS: [...allAccess, "Sales"],
        EDIT_RIGHTS: [...allAccess, "Sales"],

        VIEW_RIGHTS: [...allAccess, "Sales"],
      },
    ],
  },
  {
    MODULE_CODE: "masterSetup",
    MODULE_DESCRIPTION: "Inventory Master",
    ICON: "pi pi-cog",
    ROLES: [...allAccess, "Sales", "Design"],
    DETAIL: [
      {
        FUNCTION_DESC: "Floor Theme",
        URL: PATH?.FLOORTHEME,
        ADD_RIGHTS: [...allAccess, "Design"],
        EDIT_RIGHTS: [...allAccess, "Design"],

        VIEW_RIGHTS: [...allAccess, "Design"],
      },
      {
        FUNCTION_DESC: "Unit Type",
        URL: PATH?.UNITTYPE,
        ADD_RIGHTS: [...allAccess, "Design"],
        EDIT_RIGHTS: [...allAccess, "Design"],
        VIEW_RIGHTS: [...allAccess, "Design"],
      },
      {
        FUNCTION_DESC: "Owned By",
        URL: PATH?.OWNEDBY,
        ADD_RIGHTS: [...allAccess, "Sales"],
        EDIT_RIGHTS: [...allAccess, "Sales"],
        VIEW_RIGHTS: [...allAccess, "Sales"],
      },
    ],
  },
  {
    MODULE_CODE: "masterPayment",
    MODULE_DESCRIPTION: "Payment Master",
    ICON: "pi pi-cog",
    ROLES: [...allAccess, "Sales"],
    DETAIL: [
      {
        FUNCTION_DESC: "Taxs",
        URL: PATH?.TAX,
        ADD_RIGHTS: [...allAccess, "Sales"],
        EDIT_RIGHTS: [...allAccess, "Sales"],
        VIEW_RIGHTS: [...allAccess, "Sales"],
      },
      {
        FUNCTION_DESC: "Payment Type",
        URL: PATH?.PAYMENTTYPE,
        ADD_RIGHTS: [...allAccess, "Sales"],
        EDIT_RIGHTS: [...allAccess, "Sales"],
        VIEW_RIGHTS: [...allAccess, "Sales"],
      },
    ],
  },
  {
    MODULE_CODE: "userMaster",
    MODULE_DESCRIPTION: "User Master",
    ICON: "pi pi-user",
    ROLES: [...allAccess],
    DETAIL: [
      {
        FUNCTION_DESC: "Users Details",
        URL: PATH?.USERDETAILS,
        ADD_RIGHTS: [...allAccess],
        EDIT_RIGHTS: [...allAccess],
        VIEW_RIGHTS: [...allAccess],
      },
    ],
  },
];

export const getColumnsForRole = (
  role: string,
  columnHeaderMapping: Record<string, string>,
  roleBasedColumns: Record<string, string[]>
) => {

  const columnTitle = roleBasedColumns[role] || [];
  const customHeader = columnTitle.map((column) =>
    columnHeaderMapping[column] || ''
  ) || [];

  return { columnTitle, customHeader };
};

export const hideProjectPath = ["/inventorysetup", "/floortheme", "/unittype", "/ownedby", "/paymenttype", "/taxdetials"];





// const handleFileDownload = (
//   base64Data: any,
//   fileName: any,
//   fileExtension: any
// ) => {
//   // Convert base64 string to a Blob
//   const byteCharacters = atob(base64Data);
//   const byteNumbers = new Array(byteCharacters.length);
//   for (let i = 0; i < byteCharacters.length; i++) {
//     byteNumbers[i] = byteCharacters.charCodeAt(i);
//   }
//   const byteArray = new Uint8Array(byteNumbers);
//   const blob = new Blob([byteArray], { type: "application/octet-stream" });

//   // Create an object URL from the Blob
//   const url = URL.createObjectURL(blob);

//   // Create a link element
//   const a = document.createElement("a");
//   a.href = url;
//   a.download = `${fileName}.${fileExtension}`; // Append the file extension

//   // Append the link to the body
//   document.body.appendChild(a);

//   // Trigger the download by simulating a click
//   a.click();

//   // Clean up
//   setTimeout(() => {
//     document.body.removeChild(a);
//     URL.revokeObjectURL(url);
//   }, 0);
// };


// const getAPI = async () => {
//   try {
//     const res = await callPostAPI(ENDPOINTS.GETEXCELTEMPLATECOMMON, null);
//     TemplateDownload(res?.DATALIST, res?.JDATALIST, props?.header);
//   } catch (error: any) {
//     toast.error(error);
//   }
// };
// const Actionitems = [
//   {
//     label: t("Upload Data"),
//     icon: "pi pi-upload",
//     visible: true,
//     command: () => {
//       isUploadData();
//     },
//   },
//   {
//     label: t("Download Template"),
//     icon: "pi pi-download",
//     command: () => {
//       getAPI();
//     },
//   },
//   {
//     label: t("Download Data"),
//     icon: "pi pi-download",
//     command: () => {
//       handlerDownload();
//     },
//   },
// ];
// const isUploadData = () => {
//   setVisible(true);
// };

// const ExportCSV = (csvData: any, fileName: any) => {
//   const fileType =
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//   const fileExtension = ".xlsx";

//   for (let key in csvData) {
//     delete csvData[key].SKILL_ID;
//     delete csvData[key].FACILITY_ID;
//     delete csvData[key].PART_ID;
//     delete csvData[key].MAKE_ID;
//     delete csvData[key].WO_ID;
//     delete csvData[key].ASSETTYPE_ID;
//     delete csvData[key].ASSET_ID;
//     delete csvData[key].ASSETGROUP_ID;
//     delete csvData[key].SCHEDULE_ID;
//     delete csvData[key].TASK_ID;
//     delete csvData[key].MODEL_ID;
//     delete csvData[key].UOM_ID;
//     delete csvData[key].USER_ID;
//     delete csvData[key].ROLE_ID;
//     delete csvData[key].SKILL_ID;
//     delete csvData[key].TEAM_ID;
//     delete csvData[key].LOCATIONTYPE_ID;
//     delete csvData[key].LOCATION_ID;
//     delete csvData[key].DOC_ID;
//     delete csvData[key].STORE_ID;
//     delete csvData[key].VENDOR_ID;
//     delete csvData[key].MATREQ_ID;
//     delete csvData[key].REQ_ID;
//     delete csvData[key].EVENT_ID;
//     delete csvData[key].EVENT_TYPE;
//     delete csvData[key].OBJ_ID;
//     delete csvData[key].STATUS_CODE;
//   }

//   const ws = xlsx.utils.json_to_sheet(csvData);
//   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//   const excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" });
//   const data = new Blob([excelBuffer], { type: fileType });
//   FileSaver.saveAs(data, fileName + fileExtension);
// };

// const handlerDownload = () => {
//   if (props?.columnData.length > 0) {
//     ExportCSV(props?.columnData, props?.header);
//   } else {
//     toast.error("No Data Found");
//   }
// };
