import CryptoJS from 'crypto-js';

export const encryptData = (data: unknown): string => {
    try {
        const stringifiedData = JSON.stringify(data);
        return CryptoJS.AES.encrypt(stringifiedData, process.env.REACT_APP_SECRET_KEY as string).toString();
    } catch (error) {
        console.error('Encryption Error:', error);
        throw new Error('Encryption failed');
    }
};

export const decryptData = (encryptedData: string): unknown | null => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_SECRET_KEY as string);
        const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedString);
    } catch (error) {
        console.error('Decryption Error:', error);
        return null;
    }
};

