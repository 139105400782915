import React from "react";
import { Navigate } from "react-router-dom";
import { PATH } from "../utils/pagePath";
import { Cookies } from "react-cookie";
import { COOKIES } from "../utils/constants";
import { decryptData } from "../utils/encryption";

const ProtectedRoute = (props: any) => {
  const cookie = new Cookies();
  const decryptedData: any = decryptData(cookie.get(COOKIES.USER_INFO));
  const isSignedIn = decryptedData && decryptedData.token;
  if (!isSignedIn) {
    return <Navigate to={PATH.LOGIN} replace />;
  }

  return props.children;
};

export default ProtectedRoute;
